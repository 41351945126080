<template>
	<div class="page-web page_regi">
		<!-- <RegiHead /> -->
		<div class="regi-bd regi-bd-lg change_bd">
			<div class="regi_name">{{$t('acTxt2')}}</div>
			<el-form ref="form" class="regi-from" :rules="rules" :model="form">
				<el-form-item prop="realName">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('realtxt')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.realName" placeholder=""></el-input>
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="idCard">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('realtxt2')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.idCard" placeholder=""></el-input>
						</div>
					</div>
				</el-form-item>
				<el-form-item>
					<el-row :gutter="20">
						<el-col :xs="24" :sm="8">
							<div class="regi_group">
								<div class="regi_gr_t">{{$t('realtxt5')}}</div>
								<div class="regi_gr_b">
									<el-upload class="avatar-uploader" :action="uploadUrl" :headers="uploadHeaders"
										:show-file-list="false" :on-success="idCardFrontSuccess"
										:before-upload="beforeUpload">
										<img v-if="form.idCardFront" :src="form.idCardFront" class="avatar">
										<img v-else src="../../assets/photo/card3.png" class="avatar-uploader-icon"
											alt="">
									</el-upload>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="8">
							<div class="regi_group">
								<div class="regi_gr_t">{{$t('realtxt4')}}</div>
								<div class="regi_gr_b">
									<el-upload class="avatar-uploader" :action="uploadUrl" :headers="uploadHeaders"
										:show-file-list="false" :on-success="idCardBackSuccess"
										:before-upload="beforeUpload">
										<img v-if="form.idCardBack" :src="form.idCardBack" class="avatar">
										<img v-else src="../../assets/photo/card2.png" class="avatar-uploader-icon"
											alt="">
									</el-upload>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="8">
							<div class="regi_group">
								<div class="regi_gr_t">{{$t('realtxt3')}}</div>
								<div class="regi_gr_b">
									<el-upload class="avatar-uploader" :action="uploadUrl" :headers="uploadHeaders"
										:show-file-list="false" :on-success="handHeldIdCardSuccess"
										:before-upload="beforeUpload">
										<img v-if="form.handHeldIdCard" :src="form.handHeldIdCard" class="avatar">
										<img v-else src="../../assets/photo/card1.png" class="avatar-uploader-icon"
											alt="">
									</el-upload>
								</div>
							</div>
						</el-col>
					</el-row>
				</el-form-item>
				<div class="real-tip">
					<p>{{$t('realtxt6')}}</p>
					<p>{{$t('realtxt7')}}</p>
					<p>{{$t('realtxt8')}}</p>
				</div>
				<el-form-item>
					<div class="regi_group chan_btn flex flex-ac flex-between">
						<el-button class="btn btn_reset" size="large"  type="primary" @click="resetForm('form')">{{$t('reset')}}
						</el-button>
						<el-button class="btn" size="large"  type="primary" @click="submitForm('form')">{{$t('save')}}
						</el-button>
					</div>
				</el-form-item>
			</el-form>
			<!-- <div class="regi_tips">Copyright ©2024 Alcex All rights reserved.</div> -->
		</div>
	</div>
</template>

<script>
	import {
		realName
	} from '@/api/api/user'
	import RegiHead from '@/components/RegiHead.vue'
	export default {
		components: {
			RegiHead,
		},
		data() {
			return {
				form: {
					realName: '',
					idCard: '',
					handHeldIdCard: '',
					idCardBack: '',
					idCardFront: ''
				},
				rules: {
					realName: [{
						required: true,
						message: this.$t("realnametip"),
						trigger: "blur"
					}],
					idCard: [{
						required: true,
						message: this.$t("idcardtip"),
						trigger: "blur"
					}]
				},
				country: [],
				imageUrl: '',
				uploadUrl: this.host+"/uc/upload/oss/image",
				uploadHeaders: {
					"x-auth-token": localStorage.getItem("token")
				},
			};
		},
		created() {

		},
		mounted() {},
		methods: {
			beforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 8;

				if (!isLt2M) {
					this.$message.error(this.$t('uploaderr')+'8MB');
				}
				return isLt2M;
			},
			handHeldIdCardSuccess(res, file) {
				if (res.code == 0) {
					this.form.handHeldIdCard = file.response.data
				} else {
					this.$message.error(res.message);
				}

			},
			idCardBackSuccess(res, file) {
				if (res.code == 0) {
					this.form.idCardBack = file.response.data
				} else {
					this.$message.error(res.message);
				}
			},
			idCardFrontSuccess(res, file) {
				if (res.code == 0) {
					this.form.idCardFront = file.response.data
				} else {
					this.$message.error(res.message);
				}
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.form.handHeldIdCard == "") {
							this.$message.error(this.$t('uploadimg'));
						} else if (this.form.idCardBack == "") {
							this.$message.error(this.$t('uploadimg2'));
						} else if (this.form.idCardFront == "") {
							this.$message.error(this.$t('uploadimg3'));
						} else {
							realName(this.form).then(res => {
								if (res.code == 0) {
									this.$message.success(this.$t('save_success'));
									this.$store.commit("setMember", this.member);
									this.$router.push('/admin')
								} else {
									this.$message.error(res.message);
								}
							})
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.form.handHeldIdCard = '';
				this.form.idCardBack = '';
				this.form.idCardFront = '';
			}
		}
	}
</script>

<style>
	.avatar-uploader .el-upload {
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		width: 180px;
		height: 120px;
		display: block;
		text-align: center;
	}

	.avatar {
		width: 180px;
		height: 120px;
		display: block;
	}
</style>
